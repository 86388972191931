<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportPatrimonyView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Patrimônio",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/PatrimonyOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "barCode",
            title: "Patrimônio",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "serialNumber",
            title: "Série",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "fullName",
            title: "Produto",
            type: "text",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "categoryProductName",
            title: "Categoria",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "manufacturerName",
            title: "Fabricante",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "purchaseDate",
            title: "Data Compra",
            type: "date",
            requiredOData: false,
            selected: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "text",
            classCssTitle: "text-center",
            fieldComparison: "statusName",
            requiredOData: true,
            selected: true,
            classCssBody: "text-center",
          },
          {
            field: "numberRent",
            title: "Nº Locacão",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "customerName",
            title: "Cliente",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:220px;",
            styleBody: "max-width: 220px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "barCode",
            title: "Patrimônio",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "serialNumber",
            title: "Série",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "typeProductId",
            title: "Tipo de Produto",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/type-product/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "fullName",
            title: "Produto",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "categoryProductId",
            title: "Categoria",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/category-product/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "manufacturerId",
            title: "Fabricante",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/manufacturer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "unitOfMeasurementId",
            title: "Unidade Medida",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/unit-of-measurement/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "purchaseDate",
            title: "Data Compra",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "status",
            title: "Status",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Disponível", value: 1 },
              { title: "Locado", value: 2 },
              { title: "Manutenção", value: 3 },
              { title: "Inativo", value: 4 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "numberRent",
            title: "Nº Locação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
